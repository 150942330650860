<messages>["./components/ClientCreateUpdate"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <base-layout mw1>
    <v-col cols="12">
      <v-card class="search-result">
        <!-- toolbar for selected item operations -->
        <v-toolbar
          v-if="!!selectedItems.length"
          class="selectionSupport ma-4"
          floating>
          <v-toolbar-title
            v-text="$tc (
              'selected',
              selectedItems.length,
              {count: selectedItems.length})"/>

          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon
                class="mx-2"
                color="error"
                v-on="on"
                @click="onDeleteClients (selectedItems)">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
            <span v-t="'general.button.delete'"/>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon
                color="accent"
                :loading="isLoadingOnboard"
                v-on="on"
                @click="onOnboardingClients (selectedItems)">
                <v-icon>mail</v-icon>
              </v-btn>
            </template>
            <span v-t="'client.onboarding.title'"/>
          </v-tooltip>
        </v-toolbar>
        <v-card-title primary-title>
          <v-row align="start" justify="space-between">
            <v-col cols="12" sm="6">
              <div>
                <div
                  v-t="'title.list'"
                  class="text-h5"/>
                <div
                  v-t="'title.listSub'"
                  class="cgwng-subheading"/>
              </div>
            </v-col>
            <!-- filter -->
            <v-col v-if="frontendListOperations" cols="12" sm="6">
              <v-text-field
                v-model.trim="search"
                class="ma-0 pa-0"
                append-icon="search"
                :label="$t ('general.label.search')"
                single-line
                hide-details
                clearable
                @keyup.esc="search = ''"/>
              <v-switch
                v-model="loadInactive"
                class="mt-3"
                :label="$t ('changeStatus.loadInactive')"/>
            </v-col>
          </v-row>
        </v-card-title>

        <!-- list -->
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="clients"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.rowsPerPage"
            :sort-by.sync="pagination.sortBy"
            :sort-desc.sync="pagination.descending"
            :footer-props="footerProps"
            :server-items-length="totalCount"
            :loading="loading"
            :no-data-text="noDataText (loading)"
            :no-results-text="noResultsText (loading)"
            :value="selectedItems"
            selected-key="id"
            show-select
            class="elevation-1"
            @input="selectCB">
            <template #headerCell="props">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span
                    class="primary--text"
                    v-on="on"
                    v-text="props.header.text"/>
                </template>
                <span v-text="props.header.text"/>
              </v-tooltip>
            </template>
            <template #item="props">
              <tr>
                <td>
                  <v-checkbox
                    dense
                    :value="props.isSelected"
                    class="mx-0 my-2 pa-0"
                    color="primary"
                    hide-details
                    @change="props.select"/>
                </td>
                <td class="text-right" v-text="props.item.id"/>
                <td :class="{deletedItemText: !props.item.active}">
                  <router-link
                    :to="{name: 'client.view', params: {id: props.item.id}}"
                    v-text="props.item.name"/>
                </td>
                <td v-text="props.item.type"/>
                <td :class="{'red--text': props.item.usdBalance && props.item.usdBalance.value < 0, 'text-right': true}">
                  {{ props.item.usdBalance ? formatMoneyAmount (props.item.usdBalance.value, props.item.usdBalance.currency) : emptyMark }}<span style="visibility: hidden">)</span>
                  <div v-if="props.item.balance && props.item.balance.currency != props.item.usdBalance.currency" class="cgwng-subheading">
                    ({{ props.item.balance ? formatMoneyAmount (props.item.balance.value, props.item.balance.currency) : emptyMark }})
                  </div>
                </td>
                <td class="text-right">
                  {{ props.item.credit ? formatMoneyAmount (props.item.credit.value, props.item.credit.currency) : emptyMark }}
                </td>
                <td>
                  <action-buttons
                    :value="isActionButtonsActive (props.item.id)"
                    :buttons="getActionButtons (props.item)"
                    @input="state => setActionButtonsActive (props.item.id) (state)"
                    @clicked="processActionButton"/>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.refresh'"
            @click="listClients()"/>
          <v-btn
            v-if="mayManageSubEntities || mayManageAllEntities"
            v-t="'general.button.create'"
            color="primary"
            to="create"/>
        </v-card-actions>

        <!-- delete confirmation dialog -->
        <base-dialog
          v-if="showDeleteConfirmationDialog"
          v-model="showDeleteConfirmationDialog"
          mw0
          close-on-esc>
          <v-card>
            <v-card-title>
              <div v-t="'client.delete.title'" class="text-h5"/>
            </v-card-title>
            <v-card-text
              v-text="$tc (
                'client.delete.message',
                itemsToDelete.length, {
                  id: itemsToDelete[0].id,
                  count: itemsToDelete.length
                })"/>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                v-t="'general.button.cancel'"
                text
                @click.native="showDeleteConfirmationDialog = false"/>
              <v-btn
                v-t="'general.button.ok'"
                color="primary"
                @click.native="(deleteClients ())"/>
            </v-card-actions>
          </v-card>
        </base-dialog>

        <base-dialog
          v-model="showSelectUserDialog"
          mw0
          close-on-esc>
          <v-card>
            <v-card-title>
              <div v-t="'onboarding.passwordReset.title'" class="text-h5"/>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <p v-t="'onboarding.passwordReset.description'"/>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="selectedUserId"
                    spellcheck="false"
                    :label="$t ('onboarding.passwordReset.label')"
                    :items="userItems"/>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                v-t="'general.button.cancel'"
                text
                @click="showSelectUserDialog = false"/>
              <v-btn
                v-t="'general.button.ok'"
                color="primary"
                @click="sendOnboardingMail (client)"/>
            </v-card-actions>
          </v-card>
        </base-dialog>

        <selection-dialog
          v-model="showSelectDialog"
          :some-selected="selectedItems.length > 0"
          @select="select"/>
        <change-state-dialog
          v-model="showChangeStateDialog"
          :handle-object="changeStateObject"
          @cancel="changeStateObject = null"
          @ok="onChangeStateOk"/>
      </v-card>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapMutations, mapGetters, mapActions, mapState} from 'vuex'

  import paginationMixins from '@/app/core/mixins/PaginationComponent'
  import actionButtonsHelper from '@/app/core/mixins/ActionButtonsHelper'
  import listSelectionMixin from '@/app/core/mixins/ListSelectionHelper'

  import ActionButtons from '@/app/core/components/ActionButtons'
  import BaseLayout from '@/app/core/components/BaseLayout'
  import BaseDialog from '@/app/core/components/BaseDialog'
  import SelectionDialog from '@/app/core/components/SelectionDialog'

  import ChangeStateDialog from './components/ClientStateChangeDialog'

  import {EmptyMark} from '@/app/utils/string'

  export default {
    name: 'ClientList',

    components: {
      ActionButtons,
      BaseDialog,
      BaseLayout,
      SelectionDialog,
      ChangeStateDialog
    },

    mixins: [paginationMixins, actionButtonsHelper, listSelectionMixin],

    data () {
      return {
        // flag, which allows list filter (search) and client-side
        // sorting/paging
        frontendListOperations: true,
        isLoadingImpersonation: false,
        impersonationId: null,
        search: '',
        cachedClients: [],
        clients: [],
        totalCount: 0,
        selectedItems: [],
        itemsMatchingSearch: [],
        showDeleteConfirmationDialog: false,
        showSelectUserDialog: false,
        clientToEdit: {},
        users: [],
        selectedClient: null,
        selectedUserId: null,
        loading: true,
        isLoadingOnboard: false,
        changeStateObject: null,
        showChangeStateDialog: false,
        loadInactive: false,
        emptyMark: EmptyMark
      }
    },

    computed: {
      // mix the state into computed with object spread operator
      ...mapGetters ('auth', [
        'mayManageSubEntities',
        'mayManageAllEntities',
        'isSameClient',
        'hasAllOfPermissions',
        'isImpersonated'
      ]),

      ...mapState ('auth', [
        'userLoginData'
      ]),

      isAccAdmin () {
        return this.hasAllOfPermissions (['DoAccounting', 'ManageAllEntities'])
      },

      headers () {
        return [
          {
            text: this.$t ('client.header.id'),
            value: 'id',
            align: 'right'
          },
          {
            text: this.$t ('client.header.name'),
            value: 'name'
          },
          {
            text: this.$t ('client.header.type'),
            value: 'type'
          },
          {
            text: this.$t ('client.header.balance'),
            value: 'usdBalance',
            align: 'right'
          },
          {
            text: this.$t ('client.header.credit'),
            value: 'credit',
            sortable: false,
            align: 'right'
          },
          {
            text: this.$t ('general.label.actions'),
            sortable: false
          }
        ]
      },

      cachedItems () {
        return this.cachedClients
      },

      items () {
        return this.clients
      },

      userItems () {
        return [{
          text: this.$t ('onboarding.passwordReset.noSelection'),
          value: null
        }].concat (this.users)
      }
    },

    watch: {
      search (term) {
        this.list ()
        this.$router.replace ({
          name: this.$route.name,
          params: {
            ...this.$route.params
          },
          query: {
            ...this.$route.query,
            search: term
          }
        })
      },

      '$route.query.search': {
        handler (term) {
          if (term) {
            this.search = term
          }
        },
        immediate: true
      },

      '$route.query.inactive': {
        handler (inactive) {
          this.loadInactive = (inactive === 'true') || (inactive === true)
        },
        immediate: true
      },

      loadInactive (inactive) {
        this.cachedClients = []
        this.listClients (true)
        this.$router.replace ({
          name: this.$route.name,
          params: {
            ...this.$route.params
          },
          query: {
            ...this.$route.query,
            inactive
          }
        })
      }
    },

    created () {
      this.pagination.sortBy = 'id'
      this.pagination.rowsPerPage = 10
      // this is a workaround to enforce data retrieve from BE
      // even if the pagination and other request parameters are not changed
      // (otherwise data are not fetched on page reload and browser "back" in history)
      // TODO, FIXME: need better solution to prevent request duplication
      this.onPaginationStateChanged (this.internalPaginationState)
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess',
        setEffectiveClientData: 'auth/setEffectiveClientData'
      }),
      ...mapActions ({
        fetchData: 'request/fetchData',
        impersonate: 'auth/impersonateClient'
      }),

      /**
       * calculate action buttons for specified item
       *
       * @param item      item in the list for which action buttons should be
       *                  calculated
       */
      getActionButtons (item) {
        const view = [{
          action: 'view',
          itemId: item.id,
          icon: 'visibility',
          tooltip: this.$t ('general.button.view')
        }]

        const edit = [{
          action: 'edit',
          itemId: item.id,
          icon: 'edit',
          tooltip: this.$t ('general.button.edit')
        }]

        const clone = [{
          action: 'clone',
          itemId: item.id,
          icon: 'content_copy',
          tooltip: this.$t ('general.button.clone')
        }]

        const impersonate = [{
          action: 'impersonate',
          actionArg: item,
          isLoading: this.impersonationId === item.id && this.isLoadingImpersonation,
          icon: 'supervisor_account',
          disabled: this.isSameClient (item.id) || !item.active || this.isImpersonated,
          tooltip: this.$t ('client.impersonate.title')
        }]

        const createLimit = [...this.isAccAdmin
          ? [
            {
              action: 'createLimit',
              actionArg: item.id,
              icon: 'attach_money',
              disabled: item.type === 'Reseller' || !item.active,
              tooltip: this.$t ('label.createLimit')
            }]
          : []]

        const changeStatus = [{
          action: 'changeStatus',
          actionArg: item,
          icon: item.active ? 'stop' : 'play_arrow',
          tooltip: this.$t (`changeStatus.${item.active ? 'deactivate' : 'activate'}`)
        }]

        const onboarding = [{
          action: 'onboarding',
          actionArg: item,
          icon: 'mail_outline',
          disabled: !item.active,
          tooltip: this.$t ('client.onboarding.title')
        }]

        const deleteAction = [{
          action: 'delete',
          actionArg: [item],
          icon: 'delete',
          tooltip: item.hasUsers
            ? this.$t ('client.delete.usersExist')
            : this.$t ('general.button.delete'),
          color: 'error',
          disabled: item.hasUsers
        }]

        return [
          ...view,
          ...edit,
          ...clone,
          ...createLimit, // only account admins
          {divider: true},
          ...changeStatus,
          ...deleteAction,
          {divider: true},
          ...onboarding,
          ...impersonate
        ]
      },

      list () {
        this.listClients ()
      },

      async requestClientList (params) {
        const data = await this.fetchData ({
          op: 'client/list/all',
          params
        })

        return [data.list || [], data.totalCount || 0]
      },

      /**
       * Load the users associated with the given client and sort them
       * lexicographically.
       *
       * @param {Number} clientId      the ID of the client
       */
      loadClientUsers (clientId) {
        this.fetchData ({
          op: 'user/list',
          params: {
            clientId: clientId
          },
          cb: data => {
            this.users = data.list.map (d => ({
              text: d.displayName,
              value: d.id
            })).sort ((u1, u2) => u1.text.localeCompare (u2.text))
          }
        })
      },

      /**
       * populate data table properties according to current search and
       * pagination parameters (client-side filter, sorting, slicing)
       */
      performListOperation () {
        // filter
        if (this.search) {
          const searchNormalized = this.search.trim ().toLocaleLowerCase ()
          this.clients = this.cachedClients.filter (o => {
            let match
            for (const prop in o) {
              if (o.hasOwnProperty (prop) && typeof o[prop] === 'string') {
                match = o[prop].toLocaleLowerCase ().includes (searchNormalized)
                if (match) {
                  break
                }
              } else if (o.hasOwnProperty (prop) && typeof o[prop] ===
                'number') {
                match = (`${o[prop]}`).includes (searchNormalized)
                if (match) {
                  break
                }
              }
            }
            return match
          })
        } else {
          this.clients = this.cachedClients.slice ()
        }
        this.totalCount = this.clients.length
        // sort
        if (this.pagination.sortBy) {
          this.clients.sort ((a, b) => {
            let valA = a[this.pagination.sortBy]
            let valB = b[this.pagination.sortBy]
            if (typeof valA === 'string' && typeof valB === 'string') {
              valA = valA.toLocaleLowerCase ()
              valB = valB.toLocaleLowerCase ()
            }

            let cmpRes

            if (this.pagination.sortBy === 'usdBalance') {
              if (valA === null || valB === null) {
                cmpRes = valA === null ? -1 : 1
              } else {
                cmpRes = valA?.value > valB?.value ? 1 : valA?.value === valB?.value ? 0 : -1
              }
            } else {
              cmpRes = valA > valB ? 1 : valA === valB ? 0 : -1
            }
            return this.pagination.descending ? -cmpRes : cmpRes
          })
        }

        this.itemsMatchingSearch = [...this.clients]

        // slice
        if (this.pagination.page > 0 && this.pagination.rowsPerPage > 0) {
          const start = (this.pagination.page - 1) * this.pagination.rowsPerPage
          const end = start + this.pagination.rowsPerPage
          this.clients = this.clients.slice (start, end)
        }
      },

      /**
       * populate data table properties according to current search and
       * pagination parameters. If {@code this.frontendListOperations} is set,
       * then operation is performed on the client side.
       */
      async listClients (force = false) {
        this.loading = true

        if (force || this.frontendListOperations) {
          // if not cached => get from server
          if (!(this.cachedClients.length > 0)) {
            [this.cachedClients] = await this.requestClientList ({loadAccounts: true, loadInactive: this.loadInactive})
            this.totalCount = this.cachedClients.length
          }
          // filter, sorting, slicing cached items
          this.performListOperation ()
        } else {
          [this.clients, this.totalCount] =
            await this.requestClientList (
              {
                ...this.getPaginationForRequest (),
                loadAccounts: true
              })
        }

        this.loading = false
      },

      /**
       * process specified action button according to it's properties
       *
       * @param button {Object}     button to be processed
       */
      processActionButton (button) {
        switch (button.action) {
          case 'view':
            this.$router.push ({
              name: 'client.view',
              params: {id: button.itemId}
            })
            break

          case 'edit':
            this.$router.push ({
              name: 'client.edit',
              params: {id: button.itemId}
            })
            break

          case 'clone':
            this.$router.push ({
              name: 'client.clone',
              params: {
                id: button.itemId,
                isClone: true
              }
            })
            break

          case 'createLimit':
            this.$router.push ({
              name: 'creditlimit.create',
              query: {id: button.actionArg}
            })
            break

          case 'impersonate':
            this.onImpersonateClient (button.actionArg)
            break

          case 'onboarding':
            this.selectUserForOnboarding (button.actionArg)
            break

          case 'delete':
            this.onDeleteClients (button.actionArg)
            break
          case 'changeStatus':
            this.onChangeState (button.actionArg)
            break

          default:
            console.warn ('Unhandled button clicked:', button)
            break
        }
      },

      /**
       * impersonate specified client
       *
       * @param client      client to be impersonated
       */
      async onImpersonateClient (client) {
        this.isLoadingImpersonation = true
        this.impersonationId = client.id
        const success = await this.impersonate (client.id)

        if (success) {
          this.setEffectiveClientData (client)

          this.displaySuccessMessage (
            this.$t ('client.impersonate.success', {client: client.name}))

          this.$router.push ({name: 'dashboard'})
        }

        this.isLoadingImpersonation = true
        this.impersonationId = null
      },

      /**
       * Show the dialog for selecting a user whose password shall be reset
       * during the onboarding process.
       *
       * @param {Object} client     the client for which a user may be selected
       */
      selectUserForOnboarding (client) {
        this.selectedClient = client
        this.loadClientUsers (this.selectedClient.id)

        this.selectedUserId = null
        this.showSelectUserDialog = true
      },

      /**
       * Send an onboarding e-mail to the selected client.
       *
       * If also a user has been selected, his password will be reset and a
       * password reset e-mail will be sent.
       */
      sendOnboardingMail () {
        this.showSelectUserDialog = false

        this.fetchData ({
          op: 'client/mgmt/onboard',
          params: {
            clientId: this.selectedClient.id,
            userId: this.selectedUserId,
            userAction: 'reset-password'
          },
          cb: () => {
            this.displaySuccessMessage (
              this.$t ('client.onboarding.success', {
                client: this.selectedClient.name
              }))
          }
        })
      },

      /**
       * show delete confirmation dialog
       *
       * @param itemsToDelete {Array}     items to be deleted
       */
      onDeleteClients (itemsToDelete) {
        this.itemsToDelete = itemsToDelete
        this.showDeleteConfirmationDialog = true
      },

      /**
       * show change state confirmation dialog
       *
       * @param item {Array}     item that state should be changes
       */
      onChangeState (item) {
        this.changeStateObject = item
        this.showChangeStateDialog = true
      },

      onChangeStateOk () {
        this.changeStateObject = null
        this.cachedClients = []
        this.listClients (true)
      },

      /**
       * Send Onboarding mail to clients
       *
       * @param clients {Array}     clients to the email should be send to
       */

      onOnboardingClients (clients) {
        this.isLoadingOnboard = true
        const num = clients.length
        const ids = clients.map (e => e.id)
        this.fetchData ({
          op: 'client/mgmt/onboardmulti',
          params: {ids},
          cb: () => {
            this.displaySuccessMessage (
              this.$tc ('client.onboarding.successMulti', num))
            this.selectedItems = []
            this.isLoadingOnboard = false
          }
        })
      },

      /**
       * delete clients, specified by {@code this.itemsToDelete}
       * (see onDeleteClients)
       *
       * @param successCallback     function to be called in success case
       */
      deleteClients (successCallback = this.afterDelete) {
        // hide confirmation dialog
        this.showDeleteConfirmationDialog = false
        const ids = this.itemsToDelete.map (e => e.id)

        this.fetchData ({
          op: 'client/delete',
          params: {ids},
          cb: () => {
            this.displaySuccessMessage (
              this.$tc (
                'client.deleted',
                ids.length, {
                  id: ids[0],
                  count: ids.length
                }))
            successCallback (ids)
          }
        })
      },

      /**
       * state corrections after delete operation
       *
       * @param deletedIds    IDs of items, which was deleted
       */
      afterDelete (deletedIds) {
        // filter out deleted from selected items
        this.selectedItems =
          this.selectedItems.filter (it => !deletedIds.includes (it.id))
        // reset items to delete
        this.itemsToDelete = []
        // reset cache
        this.cachedClients = []
        // get the list from server
        this.listClients ()
      }
    }
  }
</script>
