/**
 * Any Component using this mixin should have a SelectionDialog component
 * in its template like this:
 * <selection-dialog
 *  v-model="showSelectDialog"
 *  :someSelected="selectedItems.length > 0"
 *  @select="select"/>
 *
 * Also it should provide these 3 data fields (readOnly):
 *  - cachedItems:          All items that are in the list
 *  - itemsMatchingSearch:  All items that match the searchterm
 *  - items:                Items that are currently on the page
 *
 * And these read write datafield:
 *  - selectedItems
 *
 * The component should manage all selected items in the field selectedItems
 *
 * The data list for selecting and deselecting items should have these
 * properties applied to it:
 *
 * :value="selectedItems"
 * @input="selectCB"
 */

const ALL_MATCHING_SEARCH_TERM = 0
const ALL = 1
const CURRENT_PAGE = 2
const NONE = 3

export default {
  data () {
    return {
      showSelectDialog: false
    }
  },

  methods: {
    selectCB (e) {
      const difference = this.selectedItems.length - e.length
      if (Math.abs (difference) < 2) {
        this.selectedItems = e
      } else if (Math.abs (difference) !== 0) {
        this.showSelectDialog = true
      }
    },

    select (type) {
      switch (type) {
        case ALL:
          this.selectedItems = [...this.cachedItems]
          break
        case ALL_MATCHING_SEARCH_TERM:
          this.selectedItems = [...this.itemsMatchingSearch]
          break
        case CURRENT_PAGE:
          this.selectedItems = [...this.items]
          break
        case NONE:
          this.selectedItems = []
          break
      }

      this.showSelectDialog = false
    }
  }
}

export const selectionTypes = {
  NONE,
  ALL,
  ALL_MATCHING_SEARCH_TERM,
  CURRENT_PAGE
}
