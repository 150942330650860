import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'

import {calculateRowsPerPageLimit} from './Pagination'

export default {
  data () {
    return {
      rowsPerPageLimit: -1
    }
  },

  computed: {
    paginationStateFromRoute () {
      const paginationState = {}
      const query = this.$route.query

      if (query) {
        ['sortBy', 'descending', 'page', 'rowsPerPage'].forEach (prop => {
          let value = query[prop]
          if (typeof value !== 'undefined') {
            switch (prop) {
              case 'descending':
                value = (value === 'true' || value === true)
                break
              case 'page':
              case 'rowsPerPage':
                value = parseInt (query[prop])
                if (isNaN (value)) {
                  value = undefined
                }
            }
          }
          if (typeof value !== 'undefined') {
            paginationState[prop] = value
          }
        })
      }

      return paginationState
    },

    /**
     * options for 'items-per-page-options' attribute of v-data-table component
     *
     * @return {*[]}      rows per page option array
     */
    rowsPerPageItems () {
      const maximum = this.rowsPerPageLimit <= 0
        ? {
          text: this.$t ('general.label.all'), value: -1
        }
        : this.rowsPerPageLimit

      return [10, 25, maximum]
    }
  },

  methods: {
    /**
     * check whether the maximal amount of result items, returned by API is
     * limited (the check is based on comparison of expected and real
     * result counts)
     *
     * @param resRowCount       amount of result items, returned by API
     * @param resTotalCount     total amount of results, returned by API
     * @param pagination        pagination state, used for API request
     */
    calculateRowsPerPageLimit (resRowCount, resTotalCount, pagination) {
      if (this.rowsPerPageLimit <= 0) {
        const calculetedLimit =
          calculateRowsPerPageLimit (resRowCount, resTotalCount, pagination)

        if (calculetedLimit !== this.rowsPerPageLimit &&
          calculetedLimit !== pagination.rowsPerPage) {
          // store the limit
          this.rowsPerPageLimit = calculetedLimit
          // change pagination state
          this.onPaginationStateChanged ({rowsPerPage: calculetedLimit})
        }
      }
    },

    /**
     * check if specified value represents the pager parameter "all"
     *
     * @param val           value to be checked
     * @return {boolean}    {@code true} if specified value is not positive
     *                      or equals to {@code this.rowsPerPageLimit}
     */
    isMaxItemsPerPage (val) {
      return val <= 0 ||
        (this.rowsPerPageLimit > 0 && val >= this.rowsPerPageLimit)
    },

    /**
     * check whether the pagination state changed
     *
     * @param newValue      new pagination state
     * @param oldValue      old pagination state
     * @return {boolean}    {@code true} if specified states "logically"
     *                      equivalent, {@code false} otherwise
     */
    isPaginationStateChanged (newValue, oldValue) {
      const {rowsPerPage: curItemsPerPage, ...curState} = oldValue
      const {rowsPerPage: newItemsPerPage, ...newState} = newValue

      const isEq = (
        (
          curItemsPerPage === newItemsPerPage ||
          (this.isMaxItemsPerPage (curItemsPerPage) &&
            this.isMaxItemsPerPage (newItemsPerPage))
        ) && (
          _isEmpty (newState) ||
          _isEmpty (curState) ||
          _isEqual (curState, newState)
        )
      )

      return !isEq
    },

    /**
     * update route parameters according to specified pagination state
     *
     * @param pagination      the pagination state, which should be stored
     *                        in route query parameters
     */
    updateRouteParams (pagination) {
      this.$router.replace ({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          ...pagination,
          // correct invalid 'rowsPerPage' parameter
          ...(
            this.isMaxItemsPerPage (pagination.rowsPerPage)
              ? {rowsPerPage: this.rowsPerPageLimit}
              : {}
          )
        }
      })
    },

    /**
     * converts specified Vuetify pagination object to object, which properties
     * are supported by common list requests
     *
     * @param paginationState   Vuetify pagination state
     * @return {Object}         object, which properties are supported by
     *                          common list requests
     */
    getPaginationForRequest (paginationState) {
      return {
        page: paginationState.page || 1,
        size:
          paginationState.rowsPerPage > 0
            ? paginationState.rowsPerPage
            : 0,
        orderByColumn: paginationState.sortBy || '',
        sortOrder: paginationState.descending
          ? 'desc'
          : 'asc'
      }
    }
  }
}
