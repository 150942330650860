<messages>["./ClientCreateUpdate"]</messages>

<template>
  <confirmation-dialog
    v-model="showDialog"
    :headline="$t (
      `changeStatus.dialog.title.${handleObject && handleObject.active ? 'deactivate' : 'activate'}`,
      handleObject)"
    :error-msg="errorMsg"
    :is-loading="isLoading"
    :cancel-action="cancel"
    @ok="request">
    <v-row>
      <v-col cols="12">
        <span>
          {{
            $t (
              `changeStatus.dialog.text.${handleObject && handleObject.active ? 'deactivate' : 'activate'}`,
              handleObject)
          }}
        </span>
      </v-col>
      <v-col v-if="showActivateSub" cols="12">
        <v-checkbox
          v-model="activateSubClients"
          :label="$t ('changeStatus.dialog.activateSub')"/>
      </v-col>
      <v-col v-if="isLoadingSubClients" cols="12">
        <v-progress-linear
          indeterminate
          class="load-progress"
          color="primary"/>
      </v-col>
      <v-col v-if="!isLoadingSubClients && subClients && subClients.length > 0" cols="12">
        <v-data-table
          :headers="headers"
          :items="subClients"
          :footer-props="{itemsPerPageOptions: [5]}">
          <template
            slot="items"
            slot-scope="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.name }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </confirmation-dialog>
</template>

<script>
  import {mapMutations, mapActions} from 'vuex'

  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'

  export default {
    name: 'ClientChangeStateDialog',

    components: {
      ConfirmationDialog
    },

    props: {
      handleObject: {
        type: Object,
        required: false,
        default: () => ({id: null, active: false}),
        validator (value) {
          return ['active', 'id', 'name'].every ((key) => {
            return value[key] !== undefined
          })
        }
      },
      value: {
        type: Boolean,
        required: true
      }
    },

    data () {
      return {
        isLoading: false,
        isLoadingSubClients: false,
        subClients: null,
        errorMsg: '',
        activateSubClients: false
      }
    },

    computed: {
      headers () {
        return [
          {
            text: this.$t ('client.header.id'),
            value: 'id'
          },
          {
            text: this.$t ('client.header.name'),
            value: 'name'
          }
        ]
      },

      showActivateSub () {
        return this.handleObject && !this.handleObject.active &&
          !this.isLoadingSubClients && this.subClients && this.subClients.length
      },

      showDialog: {
        get () {
          return this.value
        },

        set (newValue) {
          if (!newValue) {
            this.errorMsg = ''
          }

          this.$emit ('input', newValue)
        }
      }
    },

    watch: {
      handleObject (newVal) {
        if (newVal && newVal.id) {
          this.isLoadingSubClients = true

          this.fetchData ({
            op: 'client/list/sub-clients',
            params: {
              id: this.handleObject.id
            },
            cb: (data) => {
              this.subClients = data.list
            },
            cbFinal: () => {
              this.isLoadingSubClients = false
            }
          })
        }
      }
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      request () {
        this.isLoading = true
        this.errorMsg = ''

        this.fetchData ({
          op: 'client/change-state',
          params: {
            id: this.handleObject.id,
            active: !this.handleObject.active,
            ...!this.handleObject.active
              ? {
                activateSubClients: this.activateSubClients
              }
              : {}
          },
          cb: () => {
            this.showDialog = false
            this.displaySuccessMessage (
              this.$t (
                `changeStatus.dialog.success.${this.handleObject && this.handleObject.active ? 'deactivate' : 'activate'}`,
                this.handleObject
              )
            )
            this.$emit ('ok')
          },
          cbError: (data, next) => {
            if (data?.result[0]?.code === 'error/datamgmt/objassoc') {
              this.errorMsg = this.$t ('changeStatus.dialog.error')
            } else {
              next (data)
            }
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      cancel () {
        this.$emit ('cancel')
        this.errorMsg = ''
        this.showDialog = false
      }
    }
  }
</script>
