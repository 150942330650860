<messages>["./SelectionDialog"]</messages>

<template>
  <base-dialog
    v-model="showSelectDialog"
    mw0>
    <v-card>
      <v-card-title>
        <div
          v-t="'label.selectionTitle'"
          class="text-h5"/>
      </v-card-title>
      <v-card-text>
        <radio-group
          v-model="selectionType"
          :option-values="[
            ALL,
            CURRENT_PAGE,
            ALL_MATCHING_SEARCH_TERM,
            ...!someSelected ? [] : [NONE]]"
          :option-labels="[
            $t ('label.selectAll'),
            $t ('label.selectCurrentPage'),
            $t ('label.selectAllMatchingSearch'),
            ...!someSelected ? [] : [$t ('label.none')]]"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-t="'general.button.cancel'"
          text
          @click.native="cancel"/>
        <v-btn
          v-t="'label.submit'"
          color="primary"
          @click.native="select"/>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>

<script>
  import {selectionTypes} from '@/app/core/mixins/ListSelectionHelper'

  import BaseDialog from '@/app/core/components/BaseDialog'

  import RadioGroup from '@/app/core/components/RadioGroup'

  export default {
    name: 'SelectionDialog',

    components: {
      BaseDialog,
      RadioGroup
    },

    props: {
      value: Boolean,
      someSelected: Boolean
    },

    data () {
      return {
        selectionType: selectionTypes.ALL
      }
    },

    computed: {
      showSelectDialog: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      }
    },

    created () {
      this.ALL = selectionTypes.ALL
      this.ALL_MATCHING_SEARCH_TERM = selectionTypes.ALL_MATCHING_SEARCH_TERM
      this.CURRENT_PAGE = selectionTypes.CURRENT_PAGE
      this.NONE = selectionTypes.NONE
    },

    methods: {
      select (e) {
        this.$emit ('select', this.selectionType)
        if (this.selectionType !== this.NONE) {
          this.selectionType = this.NONE
        } else {
          this.selectionType = this.ALL
        }
      },

      cancel () {
        this.showSelectDialog = false
      }
    }
  }
</script>
